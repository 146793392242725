/* Ensure proper positioning for all devices */
.hero-area,
.featured-mockup {
    position: relative;
}

/* Mobile Layout: 320px and up */
@media only screen and (max-width: 767px) {
    .phone-2 {
        position: absolute;
        left: 20%;
        transform: translateX(-50%);
        bottom: 350px; /* Alt kısımdan biraz boşluk ekleyin */
        width: 80%; /* Genişliği ayarlayın */
        height: auto; /* Yüksekliğini otomatik olarak ayarlayın */
    }
}

/* Wide Mobile Layout: 480px and up */
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .phone-2 {
        position: absolute;
        left: 20%;
        transform: translateX(-50%);
        bottom: 3500px; /* Alt kısımdan biraz boşluk ekleyin */
        width: 70%; /* Genişliği ayarlayın */
        height: auto; /* Yüksekliğini otomatik olarak ayarlayın */
    }
}

