.notification {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 15px 20px;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10000;
    opacity: 0.9;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.notification.success {
    background-color: #4caf50;
}

.notification.error {
    background-color: #f44336;
}
