:root {
    --main-bg-color: #214282;
    --main-bg-hover-color: #282f45;
    --main-text-color: white;
    --message-bg-color: #DCF8C6;
    --response-bg-color: #f1f0f0;
    --border-color: #ccc;
    --scrollbar-bg-color: #f1f1f1;
    --scrollbar-thumb-color: #888;
    --scrollbar-thumb-hover-color: #555;
}

.chat-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: var(--main-bg-color);
    color: var(--main-text-color);
    padding: 15px 25px;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: background-color 0.3s ease;
}

.chat-button:hover {
    background-color: var(--main-bg-hover-color);
}

.chat-window {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 400px;
    height: 533px;
    background-color: white;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    z-index: 10000;
    animation: slide-up 0.4s ease-in-out;
}

@keyframes slide-up {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.chat-header {
    background-color: var(--main-bg-color);
    color: var(--main-text-color);
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-header-logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.chat-header span {
    flex-grow: 1;
    text-align: left;
}

.minimize-btn {
    background: none;
    border: none;
    color: var(--main-text-color);
    font-size: 1.5em;
    cursor: pointer;
}

.chat-body {
    flex: 1;
    padding: 15px;
    overflow-y: auto;
}

/* Scrollbar Styles */
.chat-body::-webkit-scrollbar {
    width: 10px;
}

.chat-body::-webkit-scrollbar-track {
    background: var(--scrollbar-bg-color);
    border-radius: 10px;
}

.chat-body::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: 10px;
}

.chat-body::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover-color);
}

.chat-footer {
    padding: 5px 15px 15px 15px;
    display: flex;
    gap: 10px;
}

.chat-footer input {
    flex: 1;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    outline: none;
}

.chat-footer button {
    padding: 10px 20px;
    background-color: var(--main-bg-color);
    color: var(--main-text-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.chat-footer button:hover {
    background-color: var(--main-bg-hover-color);
}

#messageInput {
    flex: 1;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    margin-right: 10px;
}

.message {
    margin: 5px 0;
    max-width: 80%;
    padding: 10px;
    clear: both;
}

.customer_message_container {
    width: 100%;
    text-align: right;
}

.response_message_container {
    width: 100%;
    display: flex;
    float: left;
    gap: 8px;
}

.customer_message {
    background-color: var(--message-bg-color);
    display: inline-block;
    word-wrap: break-word;
    border-radius: 10px 10px 0 10px;
}

.response_message {
    background-color: var(--response-bg-color);
    word-wrap: break-word;
    border-radius: 0 10px 10px 10px;
}

.chat-time {
    font-size: 11px;
    text-align: right;
    position: relative;
}

.seen-image {
    margin: 0 0 4px 5px;
    width: 13px;
    height: 11px;
    vertical-align: middle;
    position: relative;
}

.profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex-shrink: 0; /* Prevent the image from shrinking */
}

.chat-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 15px;
}

.chat-form-container label {
    font-weight: bold;
    margin-bottom: 5px;
    align-self: flex-start;
}

.chat-form-container input {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    font-size: 1em;
}

.chat-form-container button {
    padding: 10px 20px;
    background-color: var(--main-bg-color);
    color: var(--main-text-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
}

.chat-form-container button:hover {
    background-color: var(--main-bg-hover-color);
}

.chat-input-group {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.loading-dots {
    display: flex;
    align-items: center;
    margin-bottom: -5px;
}

.dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #3498db;
    border-radius: 50%;
    animation: bounce 0.4s infinite alternate;
}

.dot:nth-child(2) {
    animation-delay: 0.1s;
}

.dot:nth-child(3) {
    animation-delay: 0.2s;
}

@keyframes bounce {
    to {
        transform: translateY(-5px);
        background-color: #e74c3c;
    }
}

/* Medya Sorguları */
@media (max-width: 600px) {
    .chat-window {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
        border: none;
    }

    .chat-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

@media screen and (max-width: 600px) and (-webkit-device-pixel-ratio: 3) {
    .response_message_container {
        gap: 4px; /* Reduce the gap for iPhone */
    }

    .response_message {
        margin-left: 5px; /* Reduce the margin for iPhone */
    }
}
