.header-nav.fixed-header,
.header-nav.fixed-header.fixed-top,
.navbar.header-nav.fixed-header,
.dark-purple.header-nav.fixed-header {
    background: linear-gradient(to right bottom, #216AD9 40%, #1B59B3 70%) !important;
    background-color: #216AD9 !important;
}

.header-nav,
.navbar.header-nav {
    background: linear-gradient(to right bottom, #216AD9 40%, #1B59B3 70%) !important;
    background-color: #216AD9 !important;
} 